.main-left-tree-instance[data-v-2a1d6943] {
  height: calc(100% - 50px) !important;
}
.main-left-tree-instance[data-v-2a1d6943] .el-tree-node__expand-icon {
    display: none;
}
.custom-card[data-v-2a1d6943] .el-card__body {
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
